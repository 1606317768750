<template>
  <section class="path-user">
    <div class="layout-container user-login-page">
      <main role="main" class="main-content-wrapper">
        <div class="login-wrapper">
          <AuthHeader />

          <div class="block-webform-block col-md-6 col-md-offset-3">
            <h3 class="form-title">Login</h3>
            <div class="alert alert-danger" role="alert" v-if="invalidCredentials" style="margin-bottom: 10px">Invalid credentials</div>
            <form @submit.prevent="login()" ref="loginForm" class="user-login-form weblab-form" id="user-login-form">
              <div class="form-item required">
                <label for="edit-name" class="js-form-required form-required">Username (e-mail)</label>
                <input
                  required
                  placeholder="E-mail"
                  autocorrect="none"
                  autocapitalize="none"
                  label="E-mail"
                  name="login"
                  v-model="email"
                  type="email"
                  size="60"
                  maxlength="60"
                  class="form-text required"
                />
                <div class="description">Enter your WEB N.V. Online Laboratory Platform e-mail.</div>
              </div>
              <div class="form-item required">
                <label for="edit-pass" class="js-form-required form-required">Password</label>
                <input
                  placeholder="Password"
                  required
                  id="password"
                  label="Password"
                  name="password"
                  v-model="password"
                  type="password"
                  size="60"
                  maxlength="128"
                  class="form-text required"
                />
                <div class="description">Enter the password that accompanies your e-mail.</div>
              </div>
              <div class="clearfix"></div>
              <div class="buttons-wrapper">
                <router-link :to="{ name: 'ForgotPassword' }" style="font-size: 1.1rem">Forgot Password</router-link>
                <div data-drupal-selector="edit-actions" class="form-actions js-form-wrapper form-wrapper" id="edit-actions">
                  <input type="submit" id="edit-submit" name="op" value="Log in" class="button js-form-submit form-submit" />
                  <span class="glyphicon glyphicon-arrow-right"></span>
                </div>
              </div>
            </form>
          </div>
          <router-link to="register" class="btn-register">Create an Account</router-link>
        </div>
      </main>
    </div>
  </section>
</template>

<script>
import AuthHeader from '@/components/generic/AuthHeader.vue';
import bowser from 'bowser';
import { mapActions, mapMutations } from 'vuex';

export default {
  components: {
    AuthHeader,
  },
  data() {
    return {
      email: null,
      password: null,
      invalidCredentials: false,
    };
  },
  created() {
    if (localStorage.getItem('my_lab_logged_in') == 'true') {
      this.$router.push({ name: 'Home' }).then((_) => location.reload());
    }
  },
  methods: {
    ...mapActions({
      getOrdersStatesCount: 'getOrdersStatesCount',
      getOrderStates: 'getOrderStates',
    }),
    ...mapMutations({
      setBrowserData: 'setBrowserData',
      setPrestineInitialData: 'setPrestineInitialData',
    }),
    async login() {
      this.invalidCredentials = false;
      this.$store.commit('setTransLoader', true);
      this.$store
        .dispatch('LOGIN', { email: this.email, password: this.password })
        .then((res) => {
          if (res.result === true) {
            this.$router.push({ name: 'Home' }).then((_) => {
              this.setBrowserData(bowser);
              this.setPrestineInitialData();
              this.getOrdersStatesCount();
              this.getOrderStates();
            });
          }
        })
        .catch((e) => {
          console.log(e);
          this.invalidCredentials = true;
          this.$store.commit('setTransLoader', false);
        })
        .finally(() => {
          this.$store.commit('setTransLoader', false);
        });
    },
  },
};
</script>

<style lang="scss">
.user-login-page {
  .btn-register {
    margin-top: 40px;
    margin-left: 10px;
    line-height: 1.5rem;
    text-align: center;
    box-shadow: none;
    font-size: 1.2rem;
  }
  .buttons-wrapper {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

@media only screen and (max-width: 480px) {
  .user-login-page .block-webform-block {
    padding: 20px;
  }
}
</style>